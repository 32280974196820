import { assertResponseSuccess } from "providers/functions";
import { CosmosCreateProvidersProps } from '../CosmosRAProvidersContext';

import {
    GetWorkflowByIdResult,
    GetWorkflowsResult,
} from 'utility/types/dataProvider';

// -----------------------------------------------
// ------------ WORKFLOWS PROVIDER ----------------
// -----------------------------------------------
export const createWorkflowsProvider = ({ apiUrl, httpClient }: CosmosCreateProvidersProps) => ({
    // Get all site workflows
    getWorkflows: async (): Promise<GetWorkflowsResult> => {
        try {
            const url = `${apiUrl}/core/workflow/`;
            const { json, status } = await httpClient(url, { method: 'GET' });
            await assertResponseSuccess(json, status);
            return json.data;
        } catch (error) {
            console.error('[dataProvider] getWorkflows() error:' + error?.toString());
            throw error;
        }
    },

    // Retrieve a workflow
    getWorkflowById: async (workflowId: string): Promise<GetWorkflowByIdResult> => {
        try {
            const url = `${apiUrl}/core/workflow/${workflowId}/`;
            const { json, status } = await httpClient(url, { method: 'GET' });
            await assertResponseSuccess(json, status);
            return json.data;
        } catch (error) {
            console.error('[dataProvider] getWorkflowById() error:' + error?.toString());
            throw error;
        }
    },
});