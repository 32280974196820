import { CosmosCreateProvidersProps } from '../CosmosRAProvidersContext';

import {
    DefaultSavedSearchResult,
} from 'utility/types/dataProvider';

// -----------------------------------------------
// ------------ SAVED SEARCHES PROVIDER ----------
// -----------------------------------------------
export const createSavedSearchesProvider = ({ apiUrl, httpClient, queryClient }: CosmosCreateProvidersProps) => ({
    // Get default saved search by application
    getDefaultSavedSearch: async (app?: string): Promise<DefaultSavedSearchResult> => {
        try {
            const response = await httpClient(`${apiUrl}/core/savedsearch/default/${app}/`);
            const json = await response.json;
            return json; // Return the JSON payload as the default search
        } catch (error) {
            const typedError = error as any; // We need a ticket to fix this 404 behavior asap.
            // This endpoint considers 404 as a valid response as in no default search is set.
            // Return something custom to handle 404 specifically
            if (typedError?.status === 404) {
                return {
                    status: 404,
                    data: {
                        payload: undefined,
                    },
                };
            } else {
                console.error('[dataProvider] getDefaultSearch() error:', error?.toString());
                throw error; // Reduce 404 spam in console.
            }
        }
    },

    // Set default saved search by application
    setDefaultSavedSearch: async (id: string): Promise<any | null> => {
        try {
            const { json } = await httpClient(
                `${apiUrl}/core/savedsearch/default/editorial/${id}/`,
                { method: 'POST' },
            );

            if (queryClient) {
                await queryClient.invalidateQueries({
                    queryKey: ['getDefaultSavedSearch'], // Invalidate getDefaultSavedSearch hook keys
                });
            }

            return json;
        } catch (error) {
            console.error('[dataProvider] setDefaultSearch() error:' + error?.toString());
            throw error;
        }
    },

    // Set default saved search by application
    removeDefaultSavedSearch: async (): Promise<any | null> => {
        try {
            const { json } = await httpClient(
                `${apiUrl}/core/savedsearch/default/editorial/`,
                { method: 'DELETE' },
            );

            if (queryClient) {
                await queryClient.invalidateQueries({
                    queryKey: ['getDefaultSavedSearch'], // Invalidate getDefaultSavedSearch hook keys
                });
            }

            return json;
        } catch (error) {
            console.error(
                '[dataProvider] removeDefaultSavedSearch() error:' + error?.toString(),
            );
            throw error;
        }
    },
});