import { assertResponseSuccess } from "providers/functions";
import { CosmosCreateProvidersProps } from '../CosmosRAProvidersContext';
import { fetchUtils } from 'react-admin';

import {
  ChatQueryParams,
  ChatQueryResult,
  ChatRequest,
  OmnibarGPTQueryParams,
  OmnibarGPTQueryResult,
} from 'utility/types/dataProvider';

// -----------------------------------------------
// ------------ AI PROVIDER ---------------------
// -----------------------------------------------
export const createArtificalIntelligenceProvider = ({ apiUrl, httpClient, queryOptions }: CosmosCreateProvidersProps) => ({
  // Open AI chat
  openAIChat: async ({ messages }: ChatQueryParams): Promise<ChatQueryResult> => {
    try {
      const payload: ChatRequest = {
        messages: messages,
      };
      const url = `${apiUrl}/core/openai/chat/`;
      const { json, status } = await httpClient(url, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      await assertResponseSuccess(json, status);

      const responseData = json;
      const result: ChatQueryResult = {
        status: 'success',
        data: responseData.choices[0].message.content,
      };
      return result;
    } catch (error) {
      console.error('[dataProvider] chatWithOpenAI() error:', error?.toString());
      throw error;
    }
  },

  // Query the Omnibar AI API Endpoint
  getOmnibarGPTQuery: async ({
    query,
  }: OmnibarGPTQueryParams): Promise<OmnibarGPTQueryResult> => {
    try {
      const payload = {
        ...(query && { query: query }),
      };
      const url = `${apiUrl}/core/openai/omnibar/?${fetchUtils.queryParameters(
        payload,
        queryOptions,
      )}`;
      const { json, status } = await httpClient(url, { method: 'GET' });
      await assertResponseSuccess(json, status);

      const result: OmnibarGPTQueryResult = {
        status: json.status,
        data: {
          function: json.data.function,
          args: json.data.args,
        },
      };
      return result;
    } catch (error) {
      console.error('[dataProvider] getOmnibarGPTQuery() error:' + error?.toString());
      throw error;
    }
  },
});