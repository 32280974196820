import { assertResponseSuccess } from 'providers/functions';
import { CosmosCreateProvidersProps } from '../CosmosRAProvidersContext';
import { BlockSearchData } from 'utility/types/dataProvider';

export const createBlockLibraryProvider = ({
  apiUrl,
  httpClient,
}: CosmosCreateProvidersProps) => ({
  // Search blocks for a URL map
  getBlockSearch: async (
    map_id: string,
    query: string = '',
    limit: number = 10,
    page: number = 0,
  ): Promise<BlockSearchData> => {
    try {
      const queryString = new URLSearchParams({
        query,
        limit: limit.toString(),
        page: page.toString(),
      }).toString();

      const { json, status } = await httpClient(
        `${apiUrl}/core/block/${map_id}/?${queryString}`,
      );
      await assertResponseSuccess(json, status);

      return json.data as BlockSearchData;
    } catch (error) {
      console.error('[dataProvider] searchBlocks() error:' + error?.toString());
      throw error;
    }
  },
});
