import { assertResponseSuccess } from "providers/functions";
import { CosmosCreateProvidersProps } from '../CosmosRAProvidersContext';
import { fetchUtils } from 'react-admin';

import {
    GetKeywordListResult,
    GetSectionListResult,
    KeywordListItem,
    KeywordListSearchParams,
    SectionListItem,
    SectionListSearchParams,
    SectionTreeResult,
} from 'utility/types/dataProvider';

// -----------------------------------------------
// ------------ SITE TAXONOMY PROVIDER -----------
// -----------------------------------------------
export const createSiteTaxonomyProvider = ({ apiUrl, httpClient, queryOptions }: CosmosCreateProvidersProps) => ({
    // Get keywords list
    getKeywordList: async (
        params: KeywordListSearchParams,
    ): Promise<GetKeywordListResult> => {
        try {
            const payload = {
                ...(params.query && { query: params.query }),
                ...(params.page && { page: params.page }),
                ...(params.limit && { limit: params.limit > 100 ? 99 : params.limit }),
            };
            const url = `${apiUrl}/core/keyword/list/?${fetchUtils.queryParameters(
                payload,
                queryOptions,
            )}`;
            const { json, status } = await httpClient(url, { method: 'GET' });
            await assertResponseSuccess(json, status);

            const result: GetKeywordListResult = {
                status: json.status,
                data: {
                    total: json.data.total,
                    next: json.data.next,
                    prev: json.data.prev,
                    items: json.data.items.map((item: KeywordListItem) => ({
                        ...item,
                    })),
                },
            };
            return result;
        } catch (error) {
            console.error('dataProvider.getKeywordList - error:', error);
            throw error;
        }
    },

    // Get site section tree
    getSectionTree: async (
        id?: number | null | undefined,
    ): Promise<SectionTreeResult['data']> => {
        try {
            const endpoint = id
                ? `${apiUrl}/core/section/tree/?parent=${id}`
                : `${apiUrl}/core/section/tree/`;

            const { json, status } = await httpClient(endpoint);
            await assertResponseSuccess(json, status);

            const result: SectionTreeResult = json;
            return result.data; // Return section tree
        } catch (error) {
            console.error('[dataProvider] getSectionTree() error:' + error?.toString());
            throw error;
        }
    },

    // Get section list
    getSectionList: async (
        params: SectionListSearchParams,
    ): Promise<GetSectionListResult> => {
        try {
            const payload = {
                ...(params.query && { query: params.query }),
                ...(params.page && { page: params.page }),
                ...(params.limit && { limit: params.limit > 100 ? 99 : params.limit }),
            };
            const url = `${apiUrl}/core/section/list/?${fetchUtils.queryParameters(
                payload,
                queryOptions,
            )}`;
            const { json, status } = await httpClient(url, { method: 'GET' });
            await assertResponseSuccess(json, status);

            const result: GetSectionListResult = {
                status: json.status,
                data: {
                    total: json.data.total,
                    next: json.data.next,
                    prev: json.data.prev,
                    items: json.data.items.map((item: SectionListItem) => ({
                        ...item,
                    })),
                },
            };
            return result;
        } catch (error) {
            console.error('[dataProvider] getSectionList() error:' + error?.toString());
            throw error;
        }
    },
});