import { assertResponseSuccess } from 'providers/functions';
import { CosmosCreateProvidersProps } from '../CosmosRAProvidersContext';
import { BlockLayoutResponse } from 'utility/types/dataProvider';

export const createBlockLayoutProvider = ({
  apiUrl,
  httpClient,
}: CosmosCreateProvidersProps) => ({
  // Create new block layout for a URL
  createBlockLayout: async (
    map_id: string,
    url_id: string,
    data: BlockLayoutResponse,
  ): Promise<BlockLayoutResponse> => {
    try {
      const { json, status } = await httpClient(
        `${apiUrl}/core/layout/${map_id}/${url_id}/`,
        {
          method: 'POST',
          body: JSON.stringify(data),
        },
      );
      await assertResponseSuccess(json, status);

      return json.data;
    } catch (error) {
      console.error('[dataProvider] createBlockLayout() error:' + error?.toString());
      throw error;
    }
  },

  // Retrieve the active block layout for a URL
  getActiveBlockLayout: async (
    map_id: string,
    url_id: string,
  ): Promise<BlockLayoutResponse> => {
    try {
      const { json, status } = await httpClient(
        `${apiUrl}/core/layout/${map_id}/${url_id}/active/`,
      );
      await assertResponseSuccess(json, status);

      return json.data;
    } catch (error) {
      console.error('[dataProvider] getActiveBlockLayout() error:' + error?.toString());
      throw error;
    }
  },

  publishBlockLayout: async (
    map_id: string,
    url_id: string,
    layout_id: string,
  ): Promise<BlockLayoutResponse> => {
    try {
      const { json, status } = await httpClient(
        `${apiUrl}/core/layout/${map_id}/${url_id}/${layout_id}/publish/`,
        {
          method: 'PUT',
        },
      );
      await assertResponseSuccess(json, status);
      return json;
    } catch (error) {
      console.error('[dataProvider] publishBlockLayout() error:' + error?.toString());
      throw error;
    }
  },
});
