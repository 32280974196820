import { assertResponseSuccess } from "providers/functions";
import { CosmosCreateProvidersProps } from '../CosmosRAProvidersContext';
import { fetchUtils, UserIdentity } from 'react-admin';

import {
    AvailabilityResponse,
    GetAudienceByIdResult,
    GetUserAccountByIdResult,
    GetUserAccountSearchResult,
    GetUserGroupsByIdParams,
    GetUserGroupsByIdResult,
    UpdateUserGroupsByIdResult,
    UserAccountGroups,
    UserAccountSearchParams,
    UserAccountSummary,
} from 'utility/types/dataProvider';

// -----------------------------------------------
// ------------ USERS PROVIDER -------------------
// -----------------------------------------------
export const createUsersProvider = ({ apiUrl, httpClient, queryOptions }: CosmosCreateProvidersProps) => ({
    // [GET] user/account/search
    getUserAccountSearch: async (
        params: UserAccountSearchParams,
    ): Promise<GetUserAccountSearchResult> => {
        try {
            const payload = {
                ...(params.query && { query: params.query }),
                ...(params.account_type && { account_type: params.account_type }),
                ...(params.account_status && { account_status: params.account_status }),
                ...(params.auth_type && { auth_type: params.auth_type }),
                ...(params.banned && { banned: params.banned }),
                ...(params.page && { page: params.page }),
                ...(params.sort && { sort: params.sort }),
                ...(params.dir && { dir: params.dir }),
                ...(params.limit && { limit: params.limit > 100 ? 99 : params.limit }), // Limit capped to 100 by API.
            };

            const url = `${apiUrl}/user/account/search/?${fetchUtils.queryParameters(
                payload,
                queryOptions,
            )}`;
            const { json, status } = await httpClient(url, { method: 'GET' });
            await assertResponseSuccess(json, status);

            const result: GetUserAccountSearchResult = {
                status: json.status,
                data: {
                    total: json.data.total,
                    next: json.data.next,
                    prev: json.data.prev,
                    items: json.data.items.map((item: any) => ({
                        uuid: item.uuid,
                        account_type: item.account_type,
                        email: item.email,
                        screen_name: item.screen_name,
                        avatar: item.avatar,
                        first_name: item.first_name,
                        last_name: item.last_name,
                        title: item.title,
                        department: item.department,
                        ...item,
                    })),
                },
            };
            return result;
        } catch (error) {
            console.error(
                '[dataProvider] getUserAccountSearch() error:' + error?.toString(),
            );
            throw error;
        }
    },

    // Get user/author account summary by ID
    getUserAccountSummaryById: async (
        uuid: string,
    ): Promise<GetUserAccountByIdResult> => {
        try {
            const url = `${apiUrl}/user/account/${uuid}/summary/`;

            const { json, status } = await httpClient(url, { method: 'GET' });
            await assertResponseSuccess(json, status);

            const result: GetUserAccountByIdResult = {
                status: json.status,
                data: {
                    uuid: json.data.uuid,
                    account_type: json.data.account_type,
                    email: json.data.email,
                    screen_name: json.data.screen_name,
                    avatar: json.data.avatar,
                    first_name: json.data.first_name,
                    last_name: json.data.last_name,
                    title: json.data.title,
                    department: json.data.department,
                    ...json.data,
                },
            };
            return result;
        } catch (error) {
            console.error(
                '[dataProvider] getUserAccountSummaryById() error:' + error?.toString(),
            );
            throw error;
        }
    },

    getUserAccountById: async (uuid: string): Promise<UserIdentity> => {
        try {
            const url = `${apiUrl}/user/account/${uuid}/`;
            const { json, status } = await httpClient(url, { method: 'GET' });
            await assertResponseSuccess(json, status);

            const result: UserIdentity = json.data;
            return result;
        } catch (error) {
            console.error('[dataProvider] getUserAccountById() error:' + error?.toString());
            throw error;
        }
    },

    // Get the group names the active user is assigned to
    getActiveUserGroups: async (): Promise<UserAccountGroups> => {
        try {
            const url = `${apiUrl}/user/me/groups/`;
            const { json, status } = await httpClient(url, { method: 'GET' });
            await assertResponseSuccess(json, status);
            return json.data;
        } catch (error) {
            console.error(
                '[getActiveUserGroups] getActiveUserGroups() error:' + error?.toString(),
            );
            throw error;
        }
    },

    // Retrieve a light summary of the active user's account
    getActiveUserSummary: async (): Promise<UserAccountSummary> => {
        try {
            const { json, status } = await httpClient(`${apiUrl}/user/me/summary/`, {
                method: 'GET',
            });
            await assertResponseSuccess(json, status);

            const result: UserAccountSummary = {
                uuid: json.data.uuid,
                account_type: json.data.account_type,
                email: json.data.email,
                screen_name: json.data.screen_name,
                avatar: json.data.avatar,
                first_name: json.data.first_name,
                last_name: json.data.last_name,
                title: json.data.title,
                department: json.data.department,
                subscription: json.data.subscription,
                ...json.data,
            };
            return result;
        } catch (error) {
            console.error('[dataProvider] getUserById() error:' + error?.toString());
            throw error;
        }
    },

    // User: Send instant login email
    sendInstantLogin: async (uuid: string, params?: any) => {
        try {
            const url = `${apiUrl}/user/account/${uuid}/instant/`;
            const { json, status } = await httpClient(url, {
                method: 'POST',
                body: JSON.stringify(params),
            });
            await assertResponseSuccess(json, status);

            return json;
        } catch (error) {
            console.error('[dataProvider] sendInstantLogin() error:' + error?.toString());
            throw error;
        }
    },

    // User: Send password reset email
    sendPasswordReset: async (uuid: string, params?: any) => {
        try {
            const url = `${apiUrl}/user/account/${uuid}/auth/password/reset/`;
            const response = await httpClient(url, {
                method: 'POST',
                body: JSON.stringify(params),
            });
            await assertResponseSuccess(response.json, response.status);

            return response;
        } catch (error) {
            console.error('[dataProvider] sendPasswordReset() error:' + error?.toString());
            throw error;
        }
    },

    // User: Set password
    setPassword: async (uuid: string, params?: any) => {
        try {
            const url = `${apiUrl}/user/account/${uuid}/auth/password/`;
            const response = await httpClient(url, {
                method: 'PUT',
                body: JSON.stringify(params),
            });
            await assertResponseSuccess(response.json, response.status);

            return response;
        } catch (error) {
            console.error('[dataProvider] updatePassword() error:' + error?.toString());
            throw error;
        }
    },

    // User: check availability of screen_name or email
    checkAvailability: async (
        name: 'screen_name' | 'email',
        value: string,
    ): Promise<AvailabilityResponse> => {
        try {
            const url = `${apiUrl}/user/account/available/${name}/?${name}=${value}`;
            const { json, status } = await httpClient(url, { method: 'GET' });
            await assertResponseSuccess(json, status);

            const formattedResult = {
                status: json.status,
                available: json.data.available,
            };
            return formattedResult;
        } catch (error) {
            console.error('[dataProvider] checkAvailability() error:' + error?.toString());
            return {
                status: 'error',
            };
        }
    },

    // Get /user/group/names/
    getUserGroupNames: async (): Promise<string[] | null> => {
        try {
            const { json, status } = await httpClient(`${apiUrl}/user/group/names/`);
            await assertResponseSuccess(json, status);

            const groupNames: string[] = Object.values(json.data || {}).filter(
                (value): value is string => typeof value === 'string',
            );
            return groupNames;
        } catch (error) {
            console.error('[dataProvider] getUserGroupNames() error:' + error?.toString());
            throw error;
        }
    },

    // Update /user/admin/{id}/groups/
    updateUserGroupsById: async (uuid: string, groups: string[]) => {
        try {
            const url = `${apiUrl}/user/admin/${uuid}/groups/?${fetchUtils.queryParameters(
                {},
                queryOptions,
            )}`;
            const requestBody = JSON.stringify(groups);
            const { json, status } = await httpClient(url, {
                method: 'PUT',
                body: requestBody,
            });
            await assertResponseSuccess(json, status);

            const result: UpdateUserGroupsByIdResult = {
                status: json.status,
                data: json.data,
            };
            return result;
        } catch (error) {
            console.error(
                '[updateUserGroupsById] updateUserGroupsById() error:' + error?.toString(),
            );
            throw error;
        }
    },

    // Get /user/admin/{id}/groups/
    getUserGroupsById: async ({
        uuid,
    }: GetUserGroupsByIdParams): Promise<GetUserGroupsByIdResult> => {
        try {
            const url = `${apiUrl}/user/admin/${uuid}/groups/?${fetchUtils.queryParameters(
                {},
                queryOptions,
            )}`;
            const { json, status } = await httpClient(url, { method: 'GET' });
            await assertResponseSuccess(json, status);

            const result: GetUserGroupsByIdResult = {
                status: json.status,
                data: json.data,
            };
            return result;
        } catch (error) {
            console.error(
                '[getUserGroupsById] getUserGroupsById() error:' + error?.toString(),
            );
            throw error;
        }
    },

    updateUserData: async (uuid: string, params: any): Promise<UserAccountSummary> => {
        try {
            const url = `${apiUrl}/user/account/${uuid}/`;
            const { json, status } = await httpClient(url, {
                method: 'PUT',
                body: JSON.stringify(params),
            });

            await assertResponseSuccess(json, status);

            const result: UserAccountSummary = {
                uuid: json.data.uuid,
                account_type: json.data.account_type,
                email: json.data.email,
                screen_name: json.data.screen_name,
                avatar: json.data.avatar,
                first_name: json.data.first_name,
                last_name: json.data.last_name,
                title: json.data.title,
                department: json.data.department,
                subscription: json.data.subscription,
                ...json.data,
            };

            return result;
        } catch (error) {
            console.error('[dataProvider] updateUserData() error:' + error?.toString());
            throw error;
        }
    },

    // Get /dmp/audiences/{id}/
    getAudienceById: async (id: string): Promise<GetAudienceByIdResult> => {
        try {
            const url = `${apiUrl}/dmp/audiences/${id}/`;
            const { json, status } = await httpClient(url, { method: 'GET' });
            await assertResponseSuccess(json, status);
            const result: GetAudienceByIdResult = {
                status: json.status,
                data: { ...json.data },
            };
            return result;
        } catch (error) {
            console.error('[dataProvider] getAudienceById() error:' + error?.toString());
            throw error;
        }
    },

    // User: Send administrator invitation email.
    postAdminInvite: async (payload: { invitee_email: string; groups?: string[] }) => {
        try {
            const url = `${apiUrl}/user/admin/invite/`;
            const response = await httpClient(url, {
                method: 'POST',
                body: JSON.stringify(payload),
            });

            await assertResponseSuccess(response.json, response.status);

            return response;
        } catch (error) {
            console.error('[dataProvider] sendAdminInvite() error:' + error?.toString());
            throw error;
        }
    },
});