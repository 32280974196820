import gravatarUrl from 'gravatar-url';
import { StringifyOptions } from 'query-string';
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { AuthProvider, GetManyResult, UserIdentity, fetchUtils } from 'react-admin';
import { useAuth } from 'react-oidc-context';
import { useQueryClient, QueryClient } from 'react-query';
import { v4 as uuidv4 } from 'uuid';

import { capitalizeFirstLetter } from 'utility/functions';
import { BloxCustomDataProviderType } from 'utility/types/dataProvider';

import { useCosmosAuthEnv } from './CosmosAuthEnvContext';
import { useCosmos } from './CosmosContext';

import { createArtificalIntelligenceProvider } from './extensions/ArtificalIntelligence';
import { createBlockLayoutProvider } from './extensions/BlockLayout';
import { createBlockLibraryProvider } from './extensions/BlockLibrary';
import { createDashboardsProvider } from './extensions/Dashboards';
import { createEditorialProvider } from './extensions/Editorial';
import { createExperimentsProvider } from './extensions/Experiments';
import { createPreviewsProvider } from './extensions/Previews';
import { createSavedSearchesProvider } from './extensions/SavedSearches';
import { createSessionsProvider } from './extensions/Sessions';
import { createSiteTaxonomyProvider } from './extensions/SiteTaxonomy';
import { createSubscriptionsProvider } from './extensions/Subscriptions';
import { createTemplateInfoProvider } from './extensions/TemplateInfo';
import { createUrlMapProvider } from './extensions/UrlMap';
import { createUsersProvider } from './extensions/Users';
import { createUserWalletsProvider } from './extensions/UserWallets';
import { createWorkflowsProvider } from './extensions/Workflows';

import {
  getResourceDetails,
  assertResponseSuccess,
  determineUniqueId,
  relatedContent,
  stripTOC,
} from '../functions';
import BloxLoadingSplash from '../../components/generic/BloxLoadingSplash';

export interface CosmosDataProviderContextType {
  dataProvider: BloxCustomDataProviderType;
  authProvider: AuthProvider;
}

export const CosmosRAProvidersContext = createContext<
  CosmosDataProviderContextType | undefined
>(undefined);

export const useCosmosRAProviders = () => {
  const context = useContext(CosmosRAProvidersContext);
  if (!context) {
    throw new Error(
      'useCosmosRAProviders must be used within a CosmosRAProvidersContext!',
    );
  }
  return context;
};

export interface CosmosCreateProvidersProps {
  apiUrl?: string;
  httpClient?: any;
  queryOptions?: StringifyOptions;
  queryClient?: QueryClient;
}

const queryOptions: StringifyOptions = { arrayFormat: 'bracket' };

/** Builds the Auth Provider and Data Provider for React-Admin **/
export const CosmosRAProviders = ({ children }: PropsWithChildren) => {
  const auth = useAuth();
  const queryClient = useQueryClient();
  const { envState } = useCosmosAuthEnv();
  const { defaultSite } = useCosmos();
  const apiUrl = `https://${envState?.API_SERVER_URL}/rest/admin`;
  const authToken = `${capitalizeFirstLetter(String(auth.user?.token_type))} ${
    auth.user?.access_token
  }`;

  const httpClient = useCallback(
    (url: string, options: fetchUtils.Options = {}) => {
      try {
        if (!authToken || !defaultSite) {
          throw new Error(
            '[dataProvider] - httpClient() is missing Auth Token and/or X-Blox-Domain. Wont execute fetch.',
          );
        }

        const headers = new Headers({
          Accept: 'application/json',
          Authorization: authToken, // "Bearer <token>"
          'X-Blox-Domain': defaultSite, // Active site/domain for the user.
          ...options.headers, // Spread include any additional headers passed to options
          'X-Request-Id': uuidv4(), // Add a unique request ID for tracking
        });

        const finalOptions: fetchUtils.Options = {
          ...options,
          headers: headers,
        };

        return fetchUtils.fetchJson(url, finalOptions);
      } catch (error) {
        console.error('[dataProvider] httpClient() error:' + error?.toString());
        throw error;
      }
    },
    [authToken, defaultSite],
  );

  const contextValue = useMemo(() => {
    const artificalIntelligenceProvider = createArtificalIntelligenceProvider({
      apiUrl,
      httpClient,
      queryOptions,
    });
    const blockLayoutProvider = createBlockLayoutProvider({ apiUrl, httpClient });
    const blockLibraryProvider = createBlockLibraryProvider({ apiUrl, httpClient });
    const dashboardsProvider = createDashboardsProvider({ apiUrl, httpClient });
    const editorialProvider = createEditorialProvider({ apiUrl, httpClient });
    const experimentsProvider = createExperimentsProvider({ apiUrl, httpClient });
    const previewsProvider = createPreviewsProvider({ apiUrl, httpClient });
    const savedSearchesProvider = createSavedSearchesProvider({
      apiUrl,
      httpClient,
      queryClient,
    });
    const sessionsProvider = createSessionsProvider({ apiUrl, httpClient, queryOptions });
    const siteTaxonomyProvider = createSiteTaxonomyProvider({
      apiUrl,
      httpClient,
      queryOptions,
    });
    const subscriptionsProvider = createSubscriptionsProvider({
      apiUrl,
      httpClient,
      queryOptions,
    });
    const templateInfoProvider = createTemplateInfoProvider({ apiUrl, httpClient });
    const urlMapProvider = createUrlMapProvider({ apiUrl, httpClient });
    const usersProvider = createUsersProvider({ apiUrl, httpClient, queryOptions });
    const userWalletsProvider = createUserWalletsProvider({ apiUrl, httpClient });
    const workflowsProvider = createWorkflowsProvider({ apiUrl, httpClient });

    // -----------------------------------------------
    // ---------------- DATA PROVIDER ----------------
    // -----------------------------------------------
    const raDataProvider = {
      getList: async (resource: string, params: any) => {
        try {
          const query = params?.query;
          const type = params?.type;
          const meta = params?.meta;
          const filter = params?.filter;
          const page = params?.pagination?.page;
          const perPage = params?.pagination?.perPage;
          const field = params?.sort?.field;
          const order = params?.sort?.order;

          let payload = {
            ...(query && { query: query }),
            ...(type && { type: type }),
            ...(page && { page: (page - 1) * perPage }),
            ...(perPage && { limit: perPage }),
            ...(order && { dir: order }),
            ...(field && { sort: field === 'id' ? 'uuid' : field }), // Use UUID for "sort by id" - note RA by default will set 'id' and 'asc' if not set.
            ...(meta && { meta: meta }),
            ...(filter && { ...filter }), // Spread filter object last into payload from RA InfListBase, etc.
          };

          const {
            baseResource,
            subResource,
            isSearch,
            additionalPayload,
            modifyPayload,
          } = getResourceDetails(resource);

          if (modifyPayload) {
            payload = modifyPayload(payload);
          }

          payload = { ...payload, ...additionalPayload };

          const url = `${apiUrl}/${baseResource}/${subResource}/${isSearch}?${fetchUtils.queryParameters(
            payload,
            queryOptions,
          )}`;

          const { json, status } = await httpClient(url, { method: 'GET' });
          await assertResponseSuccess(json, status);

          const dataWithIds = json.data.items.map((item: any) => ({
            ...item,
            id: determineUniqueId(item, resource),
          }));

          const formattedResult = {
            data: dataWithIds,
            total: json.data.total,
          };

          return formattedResult;
        } catch (error) {
          console.error('[dataProvider] getList() error:' + error?.toString());
          throw error;
        }
      },

      getOne: async (resource: string, params: any) => {
        let payload = {};
        try {
          const { baseResource, subResource, additionalPayload } =
            getResourceDetails(resource);

          payload = { ...payload, ...additionalPayload };

          const url = `${apiUrl}/${baseResource}/${subResource}/${
            params.id
          }/?${fetchUtils.queryParameters(payload, queryOptions)}`;

          const { json, status } = await httpClient(url, { method: 'GET' });
          await assertResponseSuccess(json, status);

          const formattedResult = {
            data: { ...json, id: json.data.uuid },
            id: json.data.uuid,
          };
          return formattedResult;
        } catch (error) {
          console.error('[dataProvider] getOne() error:' + error?.toString());
          throw error;
        }
      },

      getMany: async (resource: string, params: any) => {
        const { baseResource, subResource } = getResourceDetails(resource);

        const fetchSingleId = async (id: string) => {
          const url = `${apiUrl}/${baseResource}/${subResource}/${id}/`;
          const { json } = await httpClient(url, { method: 'GET' });
          return json.data;
        };

        try {
          const settledResponses = await Promise.allSettled(
            params.ids.map(fetchSingleId),
          );
          const successfulResponses = settledResponses
            .filter((response) => response.status === 'fulfilled')
            .map((response) => (response as PromiseFulfilledResult<any>).value);
          const failedResponses = settledResponses
            .filter((response) => response.status === 'rejected')
            .map((response) => (response as PromiseRejectedResult).reason);
          // Log failed responses or handle them appropriately.
          if (failedResponses.length > 0) {
            console.warn('dataProvider.getMany - Some fetches failed:', failedResponses);
          }
          // If all fetches failed, throw an error
          if (successfulResponses.length === 0) {
            throw new Error('dataProvider.getMany - All fetches failed.');
          }

          // Only return the successful responses, any failed responses won't be included.
          const results: GetManyResult = {
            data: successfulResponses.map((item: any) => ({
              ...item,
              id: item.uuid,
            })),
          };

          return results;
        } catch (error) {
          console.error('[dataProvider] getMany() error:' + error?.toString());
          throw error;
        }
      },

      getManyReference: async (resource: string, params: any) => {
        try {
          const { baseResource, subResource } = getResourceDetails(resource);
          const { page, perPage } = params.pagination;
          const { field, order } = params.sort;
          let payload = {
            ...params.filter,
            [params.target]: params.id,
            _page: page,
            _limit: perPage,
            _sort: field,
            _order: order,
          };

          const url = `${apiUrl}/${baseResource}/${subResource}/?${fetchUtils.queryParameters(
            payload,
            queryOptions,
          )}`;

          const { json, status } = await httpClient(url, { method: 'GET' });
          await assertResponseSuccess(json, status);

          const formattedResult = json.data.items.map((item: any) => ({
            ...item,
            id: item.uuid,
          }));
          return formattedResult;
        } catch (error) {
          console.error('[dataProvider] getManyReference() error:' + error?.toString());
          throw error;
        }
      },

      create: async (resource: string, params: any) => {
        try {
          const { baseResource, subResource } = getResourceDetails(resource);

          const url = `${apiUrl}/${baseResource}/${subResource}/`;

          // Add the preview parameters to the data
          let payload = {
            ...params.data,
          };

          // Process related content and strip TOC
          const relatedPayload = await relatedContent(payload);
          const finalPayload = await stripTOC(relatedPayload);

          const { json, status } = await httpClient(url, {
            method: 'POST',
            body: JSON.stringify(finalPayload),
          });
          await assertResponseSuccess(json, status);

          const formattedResult = {
            data: { ...json, id: json.uuid },
          };
          return formattedResult;
        } catch (error) {
          console.error('[dataProvider] create() error:' + error?.toString());
          throw error;
        }
      },

      update: async (resource: string, params: any) => {
        try {
          const { baseResource, subResource, additionalPayload } =
            getResourceDetails(resource);
          const url = `${apiUrl}/${baseResource}/${subResource}/${params.id}/`;

          // Add the preview parameters to the data
          let payload = {
            ...params.data,
          };

          payload = { ...payload, ...additionalPayload };

          // Process related content and strip TOC
          const relatedPayload = await relatedContent(payload);
          const finalPayload = await stripTOC(relatedPayload);

          const { json, status } = await httpClient(url, {
            method: 'PUT',
            body: JSON.stringify(finalPayload),
          });

          await assertResponseSuccess(json, status);

          const formattedResult = {
            data: { ...json, id: json.uuid },
          };
          return formattedResult;
        } catch (error) {
          console.error('[dataProvider] update() error:' + error?.toString());
          throw error;
        }
      },

      updateMany: async (resource: string, params: any) => {
        const { baseResource, subResource } = getResourceDetails(resource);
        try {
          const query = {
            filter: JSON.stringify({ id: params.ids }),
          };

          const url = `${apiUrl}/${baseResource}//${subResource}/?${fetchUtils.queryParameters(
            query,
            queryOptions,
          )}`;
          const { json, status } = await httpClient(url, {
            method: 'PATCH',
            body: JSON.stringify(params.data),
          });

          await assertResponseSuccess(json, status);

          const formattedResult = {
            data: json,
          };
          return formattedResult;
        } catch (error) {
          console.error('[dataProvider] updateMany() error:' + error?.toString());
          throw error;
        }
      },

      delete: async (resource: string, params: any) => {
        const { baseResource, subResource } = getResourceDetails(resource);
        try {
          const url = `${apiUrl}/${baseResource}/${subResource}/${params.id}/`;
          const { json, status } = await httpClient(url, {
            method: 'DELETE',
          });
          await assertResponseSuccess(json, status);

          const formattedResult = {
            data: json,
          };
          return formattedResult;
        } catch (error) {
          console.error('[dataProvider] delete() error:' + error?.toString());
          throw error;
        }
      },

      deleteMany: async (resource: string, params: any) => {
        const { baseResource, subResource } = getResourceDetails(resource);

        try {
          const url = `${apiUrl}/${baseResource}/${subResource}/delete/`;
          const deleteObject = { uuid: params.ids };

          const { json, status } = await httpClient(url, {
            method: 'POST',
            body: JSON.stringify(deleteObject),
          });
          await assertResponseSuccess(json, status);

          const formattedResult = {
            data: json,
          };
          return formattedResult;
        } catch (error) {
          console.error('[dataProvider] deleteMany() error:' + error?.toString());
          throw error;
        }
      },
    };

    // -----------------------------------------------
    // ---------------- AUTH PROVIDER ----------------
    // -----------------------------------------------
    const authProvider: AuthProvider = {
      // Note: CosmosAuthEnvContext / WithAuthChecks contains an useEffect which
      // handles redirecting to login page when not authenticated.
      // The 'login' here is likely unused, but React-Admin needs it.
      // The 'logout' is used on the logout button, but redirecting post logout is handled by the effect.

      login: async () => {
        const redirect_uri = window.location.origin;
        const nav_to_cosmos_route = window.location.href.replace(
          window.location.origin,
          '',
        );
        try {
          await auth.signinRedirect({
            redirect_uri: redirect_uri,
            state: { nav_to_cosmos_route: nav_to_cosmos_route },
          });
          return Promise.resolve();
        } catch (error) {
          console.error('[authProvider] login() error:' + error?.toString());
          return Promise.reject(error);
        }
      },

      logout: async (params) => {
        try {
          // await auth.signoutRedirect({/* TNCMS-554796 */}); (?)
          // ^^ This will likely be used once we get the official endpoint. TNCMS-554796
          // For now we manually kill the token and hit the existing endpoint ourselves.
          const response = await fetch(`${envState?.OIDC_SERVER_URL}api/-/logout`, {
            method: 'POST',
            credentials: 'include',
          });

          if (!response.ok) {
            throw new Error(
              'fetch/POST logout on the server response not ok.' +
                JSON.stringify(response, null, 2),
            );
          }

          const json = await response.json();

          if (!json.success) {
            throw new Error(
              'Failed to logout on the server?' + JSON.stringify(json, null, 2),
            );
          }

          // Response successful, now we can logout on the client.
          await auth.clearStaleState();
          await auth.removeUser();

          // Cleanup react-query client, about to nav off site to oidc login page.
          queryClient.clear();
          await queryClient.resetQueries();

          // Also clear session storage for extra safety.
          sessionStorage.clear();

          return Promise.resolve();
        } catch (error) {
          console.error('[authProvider] logout() error:' + error?.toString());
          // (partial failsafe) - If we fail to logout on the server, we still could still try to 'logout' on the client, clearing the token.
          queryClient.clear();
          await queryClient.resetQueries();
          await auth.clearStaleState();
          await auth.removeUser();
          sessionStorage.clear();
          return Promise.reject(error);
        }
      },

      checkAuth: async () => {
        try {
          if (!auth.user) {
            throw new Error('User not found while checking auth.');
          }
          if (!auth.isAuthenticated) {
            throw new Error('User unauthenticated.');
          }
          return Promise.resolve();
        } catch (error) {
          console.error('[authProvider] checkAuth() error:' + error?.toString());
          return Promise.reject(error);
        }
      },

      getIdentity: async (): Promise<UserIdentity> => {
        try {
          if (!auth.user) {
            throw new Error('User not found while getting identity.');
          }
          if (!auth.isAuthenticated) {
            throw new Error('User unauthenticated.');
          }
          if (!auth.user.profile.email) {
            throw new Error('User profile email not found while getting identity.');
          }
          const gravatarIconUrl = gravatarUrl(auth.user.profile.email, {
            size: 64,
            default: 'mm',
          });
          return Promise.resolve({
            id: String(auth.user.profile.email),
            avatar: gravatarIconUrl,
            uuid: auth?.user?.profile?.sub ?? '',
          });
        } catch (error) {
          console.error('[authProvider] getIdentity() Error :' + error?.toString());
          return Promise.reject(error);
        }
      },

      checkError: async (error: any) => {
        try {
          const status = error.status || error.response?.status;
          if (auth.error || status === 401 || status === 403) {
            console.error('Status 401 / 403 / Auth Error calling logout...', status);
            await authProvider.logout(error);
            return Promise.reject();
          }
          // For other errors (e.g. 404, etc...), resolve the promise to indicate no auth problem.
          return Promise.resolve();
        } catch (error) {
          console.error('[authProvider] checkError() error:' + error?.toString());
          return Promise.reject(error);
        }
      },

      getPermissions: async () => {
        return Promise.resolve(/* Permission handling stub */);
      },

      handleCallback: async () => {
        /**
         * React-admin provides a default callback URL at /auth-callback.
         * (we've disabled this on our <Admin /> component as we should handle that in our own AuthEnvContext)
         * This route calls the authProvider.handleCallback method on mount.
         * This means its the authProvider job to use the params received
         * from the callback URL to authenticate future API calls.
         */
        Promise.resolve(/* - likely unused, stubbed in - */);
      },
    };

    return {
      dataProvider: {
        ...raDataProvider,
        ...artificalIntelligenceProvider,
        ...blockLayoutProvider,
        ...blockLibraryProvider,
        ...dashboardsProvider,
        ...editorialProvider,
        ...experimentsProvider,
        ...previewsProvider,
        ...savedSearchesProvider,
        ...sessionsProvider,
        ...siteTaxonomyProvider,
        ...subscriptionsProvider,
        ...templateInfoProvider,
        ...urlMapProvider,
        ...usersProvider,
        ...userWalletsProvider,
        ...workflowsProvider,
      },
      authProvider: authProvider,
    };
  }, [auth, envState, queryClient, apiUrl, httpClient]);

  if (
    !defaultSite ||
    !auth ||
    !auth?.user?.token_type ||
    !auth?.user?.access_token ||
    !envState?.API_SERVER_URL ||
    !envState?.OIDC_SERVER_URL ||
    !queryClient
  ) {
    return <BloxLoadingSplash message="Building providers..." />;
  }

  return (
    <CosmosRAProvidersContext.Provider value={contextValue}>
      {children}
    </CosmosRAProvidersContext.Provider>
  );
};

/**
 * File Notes:
 *
 * --- Message ---
 * (pre-hooks/component version) - RA Correspondence on withLifecycleCallbacks + custom methods in dataprovider:
 *
 * Response:
 * withLifecycleCallbacks seems to only accept a basic dataProvider
 * (in the sense of without any custom methods) and returns a basic dataProvider as well.
 * However this is just a limitation of the TypeScript type currently in use, in fact you can
 * also provide an augmented dataProvider (including custom methods) and it will work too.
 *
 * withLifecycleCallbacks is only meant to override standard react-admin methods (getOne, getList, ...) so we
 * figured typing the base dataProvider as DataProvider was enough, but your question makes me
 * realize we could add support for generic types there.
 *
 * Anyways, TLDR is, the code would work both ways (regardless of what TypeScript says),
 * and the approach you described is perfectly valid.
 * Cheers, Jean-Baptiste, React Admin Support Team
 *
 *
 * --- Message ---
 * RA Correspondence on adding custom lifecycle callbacks to our custom methods, if ever needed.
 *
 * To achieve that, you can take a look at withLifecycleCallbacks() definition
 * in ra-core/src/dataProvider/withLifecycleCallbacks.ts and take inspiration from the ResourceCallbacks[] type.
 *
 * --- Here is an implementation example ---
 * const baseDataProvider = simpleRestProvider("http://path.to.my.api/");
 * const transformData = (data: any[]) => {
 *   return data; // do something with data
 * };
 *
 * interface CustomResourceCallbacks extends ResourceCallbacks {
 *   binaryUpload?: (params: any) => Promise<any>;
 *   binaryConvert?: (params: any) => Promise<any>;
 * }
 *
 * // override withLifecycleCallbacks definition
 * const customWithLifecycleCallbacks = (
 *   dataProvider: DataProvider,
 *   handlers: CustomResourceCallbacks[]
 * ) => withLifecycleCallbacks(dataProvider, handlers);
 *
 * export const dataProvider = customWithLifecycleCallbacks(baseDataProvider, [
 *   {
 *     resource: "posts",
 *     afterGetManyReference: async (result) => {
 *       // existing callback
 *       const data = transformData(result.data);
 *       return { data, total: result.total };
 *     },
 *     afterGetList: async (result) => {
 *       // existing callback
 *       const data = transformData(result.data);
 *       return { data, total: result.total };
 *     },
 *     binaryUpload: async (params) => {
 *       // your custom callback
 *       const data = transformData(params);
 *       return Promise.resolve({ data });
 *     },
 *     binaryConvert: async (params) => {
 *       // your custom callback
 *       const data = transformData(params);
 *       return Promise.resolve({ data });
 *     },
 *   },
 * ]);
 *
 */
