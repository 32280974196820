import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { GlobalStyles } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import BloxLoadingSplash from './components/generic/BloxLoadingSplash';
import { queryClient } from './providers/clients/queryClient';
import { CosmosAuthEnvProvider } from './providers/contexts/CosmosAuthEnvContext';
import { CosmosProvider } from './providers/contexts/CosmosContext';
import { CosmosActiveUserProvider } from './providers/contexts/CosmosActiveUserContext';
import { CosmosRAProviders } from './providers/contexts/CosmosRAProvidersContext';
import { globalStyles } from './themes/constants';
import { CosmosHistoryProvider } from './providers/contexts/CosmosHistoryContext';

const LazyApp = lazy(() => import('./App'));

const hoistedGlobalStyles = <GlobalStyles styles={globalStyles} />;

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Suspense fallback={<BloxLoadingSplash message="Loading Blox CMS..." />}>
    <BrowserRouter>
      <CosmosHistoryProvider>
        {hoistedGlobalStyles}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <QueryClientProvider client={queryClient}>
            <CosmosAuthEnvProvider>
              <React.StrictMode>
                <CosmosProvider>
                  <CosmosRAProviders>
                    <CosmosActiveUserProvider>
                      <LazyApp />
                    </CosmosActiveUserProvider>
                  </CosmosRAProviders>
                </CosmosProvider>
              </React.StrictMode>
            </CosmosAuthEnvProvider>
          </QueryClientProvider>
        </LocalizationProvider>
      </CosmosHistoryProvider>
    </BrowserRouter>
  </Suspense>,
);

// These lines can be used for debugging, oidc, query-client.
// import { Log } from 'oidc-client-ts';
// import { ReactQueryDevtools } from 'react-query/devtools';
// <ReactQueryDevtools initialIsOpen={true} />
// Log.setLogger(console);  Log.setLevel(Log.DEBUG);
// Hoisted the <GlobalStyles> per MUI Recommendation
// https://mui.com/material-ui/customization/how-to-customize/#:~:text=hoist%20the%20%3CGlobalStyles%20/%3E
