import { assertResponseSuccess } from "providers/functions";
import { CosmosCreateProvidersProps } from '../CosmosRAProvidersContext';

import {
    AddExperimentsResult,
} from 'utility/types/dataProvider';

// -----------------------------------------------
// ------------ EXPERIMENTS PROVIDER -------------
// -----------------------------------------------
export const createExperimentsProvider = ({ apiUrl, httpClient }: CosmosCreateProvidersProps) => ({
    updateExperiment: async (name: string, enabled: boolean): Promise<any> => {
        try {
            const params = {
                enabled: enabled,
            };
            const url = `${apiUrl}/core/experiment/${name}/`;
            const response = await httpClient(url, {
                method: 'PUT',
                body: JSON.stringify(params),
            });

            await assertResponseSuccess(response?.json, response?.status);

            const result = Promise.resolve();
            return result;
        } catch (error) {
            console.error('[dataProvider] updateExperiment() error:' + error?.toString());
            throw error;
        }
    },

    addExperiment: async (name: string): Promise<any> => {
        try {
            const params = {
                name: name,
            };
            const url = `${apiUrl}/core/experiment/`;
            const { status, json } = await httpClient(url, {
                method: 'POST',
                body: JSON.stringify(params),
            });

            await assertResponseSuccess(json, status);
            const result: AddExperimentsResult = { ...json.data }; // Key-value pair using the id as the key
            return result;
        } catch (error) {
            console.error('[dataProvider] addExperiment() error:' + error?.toString());
            throw error;
        }
    },

    deleteExperiment: async (name: string): Promise<any> => {
        try {
            const url = `${apiUrl}/core/experiment/${name}/`;
            const response = await httpClient(url, {
                method: 'DELETE',
            });

            await assertResponseSuccess(response?.json, response?.status);

            const result = Promise.resolve();
            return result;
        } catch (error) {
            console.error('[dataProvider] deleteExperiment() error:' + error?.toString());
            throw error;
        }
    },
});