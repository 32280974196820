import {
  Alert,
  AlertProps,
  AlertTitle,
  Box,
  Button,
  Divider,
  Typography,
} from '@mui/material';
import { capitalizeFirstLetter } from '../../utility/functions';
import { SxProps, Theme, alpha, useTheme } from '@mui/material/styles';

export interface ErrorDetails {
  status?: number | string | null | undefined; // Status can be a number (e.g. HTTP status code) or string
  message?: number | string | null | undefined; // Description of the error
  [key: string]: any;
}

export interface ErrorAlertsProps {
  severity?: AlertProps['severity'];
  showSeverity?: boolean; // Whether to show the severity of the alert
  headerText?: string; // headerText to display in the alert title
  headerTextFontSize?: string | number; // headerText font size
  headerTextFontWeight?: string | number; // headerText font weight
  errors?: ErrorDetails[]; // An array of objects conforming to ErrorDetails
  showDetails?: boolean; // Whether to show the error details
  buttonOnClick?: (event: React.MouseEvent<HTMLButtonElement>) => void; // The onClick event for the button (if any)
  buttonText?: string; // The buttonText for the button - must be used with buttonOnClick
  buttonIcon?: React.ReactNode; // The buttonIcon for the button - must be used with buttonOnClick
  sx?: SxProps<Theme>; // The sx prop spread to the Alert component
}

const ErrorAlerts = ({
  errors = [],
  showDetails = true,
  showSeverity = true,
  headerTextFontSize = '20px',
  headerTextFontWeight = '500',
  severity = 'error',
  headerText = '',
  sx,
  buttonOnClick,
  buttonText,
  buttonIcon,
}: ErrorAlertsProps) => {
  const theme = useTheme();
  return (
    <Alert
      severity={severity}
      action={
        buttonText && buttonOnClick ? (
          <Button
            color={severity}
            id="error-alert-button"
            variant="contained"
            disableElevation
            size="small"
            onClick={buttonOnClick}
            startIcon={buttonIcon}
          >
            {buttonText}
          </Button>
        ) : undefined
      }
      sx={{
        outline: `1px solid ${theme?.palette?.[severity]?.light}`,
        borderRadius: 1,
        p: 1,
        '& .MuiAlert-action': {
          m: 0,
          p: 0,
          ml: 'auto',
          '& .MuiButton-startIcon': {
            mr: 0.5,
            p: 0,
          },
        },
        '& .MuiAlertTitle-root': {
          fontSize: '16px',
          fontWeight: '500',
          my: 0,
          mt: 0,
          mb: 1,
          px: 1,
          border: `1px solid ${alpha(theme?.palette?.[severity]?.main, 0.25)}`,
          backgroundColor: alpha(theme?.palette?.[severity]?.light, 0.25),
          borderRadius: 1,
        },
        '& .MuiAlert-icon': {
          m: 0,
          p: 0,
          mt: '2px',
        },
        '& .MuiAlert-message': {
          py: 0,
          px: 1,
          alignSelf: 'center',
        },
        // target the headerText by id
        '& #error-header-text': {
          fontSize: headerTextFontSize,
          fontWeight: headerTextFontWeight,
        },
        ...(typeof sx === 'function' ? sx(theme) : sx),
      }}
    >
      {headerText && <Typography id="error-header-text">{headerText}</Typography>}
      {showSeverity && <AlertTitle>{`${capitalizeFirstLetter(severity)}`}</AlertTitle>}
      {errors.map((error: ErrorDetails, index) => (
        <Box
          key={index}
          id="errors-wrapper-box"
        >
          {error?.status && (
            <Typography
              id="error-status"
              title="Error Status"
              variant="h2"
              fontSize="16px"
            >
              {error.status}
            </Typography>
          )}

          {error?.message && (
            <Typography
              id="error-message"
              title="Error Message"
              fontSize="14px"
            >
              {error.message}
            </Typography>
          )}
          {showDetails ? (
            <>
              <Divider sx={{ my: 1 }} />
              <Typography
                id="error-details"
                fontWeight="500"
                fontSize="14px"
              >
                Error Details
              </Typography>
              <Typography
                id="error-details-data"
                component="pre"
                whiteSpace="pre-wrap"
                fontWeight="400"
                fontSize="14px"
                fontFamily="monospace"
              >
                {JSON.stringify(error, null, 2)}
              </Typography>
            </>
          ) : null}
          {index < errors.length - 1 && <Divider />}
        </Box>
      ))}
    </Alert>
  );
};

export default ErrorAlerts;
