import { CosmosCreateProvidersProps } from '../CosmosRAProvidersContext';

// -----------------------------------------------
// ----------- DASHBOARDS PROVIDER ---------------
// -----------------------------------------------
export const createDashboardsProvider = ({ apiUrl, httpClient }: CosmosCreateProvidersProps) => ({
    // Dashboards.
    getDashboardById: async ({ id }: { id: string }) => {
        const url: string = `${apiUrl}/insights/dashboard/${id}/`;

        try {
            const { json } = await httpClient(url, { method: 'GET' });

            return {
                status: json.status,
                data: json.data,
            };
        } catch (error) {
            console.error(error);
        }
    },

    getWidgetDataById: async ({ id }: { id: string }) => {
        try {
            const url: string = `${apiUrl}/insights/widget/${id}/data/`;
            const { json } = await httpClient(url, { method: 'GET' });
            return json.data;
        } catch (error) {
            console.error(
                `[dataProvider] getWidgetDataById() - (Widget ID): ${id} error: ${error?.toString()}`,
            );
            throw error;
        }
    },

    // Returns all RSS widgets for the current dashboard.
    // What is returned has to be mapped to the dashboard layout property.
    getAllDashboardRssWidgetModules: async (appId: string, widgetId: string) => {
        try {
            const moduleUrl = new URL(`${apiUrl}/insights/module/`);
            moduleUrl.searchParams.append('app', appId);
            moduleUrl.searchParams.append('module', 'rss');
            moduleUrl.searchParams.append('limit', '5');

            const { json } = await httpClient(moduleUrl.toString(), {
                method: 'GET',
            });

            return json.data;
        } catch (error) {
            console.error(
                `[dataProvider] getAllDashboardRssWidgets() - (ID-Node): ${widgetId} error:` +
                error?.toString(),
            );
            throw error;
        }
    },
});