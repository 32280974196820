import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(timezone);
dayjs.extend(utc);

/**
 * Utility Constants
 */

// Unicode characters
export const emdash = '\u2014';
export const endash = '\u2013';
export const ellipsis = '\u2026';
export const verticalEllipsis = '\u22EE';
export const nbsp = '\u00A0';
export const moneyWingsEmoji = '\u{1F4B8}'; // or \uD83D\uDCB8
export const lightbulbEmoji = '\u{1F4A1}'; // or \uD83D\uDCA1

/**
 * Date Formats: https://day.js.org/docs/en/display/format
 */

// Sep 23, 2024
export const MMM_DD_YYYY: Intl.DateTimeFormatOptions = {
  month: 'short',
  day: '2-digit',
  year: 'numeric',
};

// 12/31/21
export const MM_DD_YY: Intl.DateTimeFormatOptions = {
  day: '2-digit',
  month: '2-digit',
  year: '2-digit',
};

// Friday, December 31, 2021
export const WEEKDAY_MMMM_DD_YYYY: Intl.DateTimeFormatOptions = {
  weekday: 'long',
  month: 'long',
  day: '2-digit',
  year: 'numeric',
};

// Date/Time Constants generally for mui datepickers
export const absMaxDateTime = dayjs('9999-12-31T23:59:59.999Z').utc(); // note: can possibly lag datepickers open
export const absMinDateTime = dayjs('100-01-01T00:00:00.000Z').utc();
export const safeMaxDateTime = dayjs('2500-12-31T23:59:59.999Z').utc(); // more reasonable max / mins
export const safeMinDateTime = dayjs('1500-01-01T00:00:00.000Z').utc();

// cache time constants
export const fiveMinMs = 300000;
