import Alert from '@mui/material/Alert';
import { AlertTitle, Typography } from '@mui/material';

const NoCosmosPermissions = () => {
  return (
    <Alert
      severity="info"
      sx={(theme) => ({
        m: 1,
        borderRadius: 1,
        border: `1px solid ${theme.palette.info.light}`,
      })}
    >
      <AlertTitle>No Permissions</AlertTitle>
      <Typography
        variant="body1"
        my={1}
      >
        This site or account is currently not participating in the BLOX CMS Admin alpha.
      </Typography>
    </Alert>
  );
};

export default NoCosmosPermissions;
