import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useAuth } from 'react-oidc-context';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import BloxLoadingSplash from '../../components/generic/BloxLoadingSplash';
import ErrorAlerts, { ErrorDetails } from '../../components/generic/ErrorAlerts';
import NoCosmosPermissions from '../../components/generic/NoCosmosPermissions';
import { capitalizeFirstLetter } from '../../utility/functions';
import {
  ApiErrorResponse,
  SessionConfigResult,
  UserAccountFull,
} from '../../utility/types/dataProvider';
import { useCosmosAuthEnv } from './CosmosAuthEnvContext';
import { assertResponseSuccess } from 'providers/functions';
import { User } from 'oidc-client-ts';

//-----------------------------------
// Types and Consts
//-----------------------------------

export interface CosmosContextType {
  changeActiveSiteAsync: (newDefaultSite: string) => Promise<void>;
  clearInvalidDomain: () => void;
  /** The currently 'active' site/domain. */
  defaultSite: string;
  invalidDomain: string;
  sessionConfig?: SessionConfigResult;
  userInfo?: UserAccountFull; // fetched from /user/me/
  authInfo?: User; // re-exported for convenience via useAuth?.user
}

interface CosmosStateType {
  defaultSite: string;
  invalidDomain: string;
  sessionConfig?: SessionConfigResult;
  userInfo?: UserAccountFull;
}

const initialState: CosmosStateType = {
  defaultSite: '',
  invalidDomain: '',
  sessionConfig: undefined,
  userInfo: undefined,
};

// Custom return type for the initialSessionConfig useQuery.
type InitialStateUpdate = Pick<
  CosmosStateType,
  'defaultSite' | 'invalidDomain' | 'sessionConfig'
>;

//-----------------------------------
// Query Functions
//-----------------------------------

// We can't access the dataProvider initially, some API calls will need to be defined here.
async function initialSessionConfig(
  apiUrl: string,
  authToken: string,
  xBloxDomain: string,
): Promise<SessionConfigResult> {
  if (!apiUrl || !authToken || !xBloxDomain) {
    throw new Error('[initialSessionConfig] Missing required parameters.');
  }

  try {
    const headers = new Headers({
      Accept: 'application/json',
      Authorization: authToken,
      'X-Blox-Domain': xBloxDomain,
      'X-Request-Id': uuidv4(),
    });

    const response = await fetch(`${apiUrl}/core/session/config/`, { headers });
    const json = await response.json();

    await assertResponseSuccess(json, response?.status);

    const result: SessionConfigResult = { ...json?.data };
    return result;
  } catch (error) {
    console.error('[initialSessionConfig] error:', error);
    throw error;
  }
}

async function getUserMe(
  apiUrl: string,
  authToken: string,
  xBloxDomain: string,
): Promise<UserAccountFull> {
  if (!apiUrl || !authToken || !xBloxDomain) {
    throw new Error('[getUserMe] Missing required parameters.');
  }

  try {
    const headers = new Headers({
      Accept: 'application/json',
      Authorization: authToken,
      'X-Blox-Domain': xBloxDomain,
      'X-Request-Id': uuidv4(),
    });

    const response = await fetch(`${apiUrl}/user/me/`, { headers });
    const json = await response.json();

    await assertResponseSuccess(json, response?.status);

    const result: UserAccountFull = { ...json?.data };
    return result;
  } catch (error) {
    console.error('[getUserMe] error:', error);
    throw error;
  }
}

//-----------------------------------
// CosmosContext Provider and Hooks
//-----------------------------------
export const CosmosContext = createContext<CosmosContextType | undefined>(undefined);

export const useCosmos = () => {
  const context = useContext(CosmosContext);
  if (!context) {
    throw new Error('useCosmos must be used within a CosmosProvider!');
  }
  return context;
};

export const CosmosProvider = ({ children }: PropsWithChildren) => {
  // hooks and states
  const auth = useAuth();
  const navigate = useNavigate();
  const { envState } = useCosmosAuthEnv();
  const queryClient = useQueryClient();
  const [cosmosState, setCosmosState] = useState<CosmosStateType>(initialState);

  // derived consts
  const authBloxCMSDefaultSite = String(
    auth?.user?.profile['bloxcms.default_site'],
  ).toLowerCase();
  const apiUrl = `https://${envState?.API_SERVER_URL}/rest/admin`;
  const authToken = `${capitalizeFirstLetter(String(auth?.user?.token_type))} ${
    auth?.user?.access_token
  }`;
  const addressBarDomain = window.location.pathname.split('/')[1]?.toLowerCase();

  const hasCosmos = Boolean(
    cosmosState?.sessionConfig?.software_privileges?.core?.includes('cosmos'),
  );
  const isStaffUser = Boolean(cosmosState?.userInfo?.account_type === 'staff');
  // Staff always allowed, otherwise must have cosmos privileges.
  const canAccessCosmos = isStaffUser || hasCosmos;

  // initCosmosQuery - fetches and initializes state once auth is valid
  const {
    data: initCosmosQueryData,
    error: initCosmosQueryError,
    status: initCosmosQueryStatus,
    refetch: initCosmosQueryRefetch,
  } = useQuery<InitialStateUpdate, ApiErrorResponse>({
    queryKey: [
      'CosmosProvider',
      'initialSessionConfig',
      addressBarDomain,
      authBloxCMSDefaultSite,
      `auth.isAuthenticated:${auth?.isAuthenticated}`,
    ],
    queryFn: async () => {
      if (!authBloxCMSDefaultSite) {
        // Can't proceed without some default site to use.
        throw new Error('OIDC Auth bloxcms.default_site is undefined/missing.');
      }

      try {
        // Edge case: localhost:3000/ (no domain in address bar), throw to oidc fallback catch block.
        if (!addressBarDomain) {
          throw new Error('URL domain is undefined, falling back to oidc domain.');
        }

        // Try using the address bar domain first.
        const sessionConfigResult = await initialSessionConfig(
          apiUrl,
          authToken,
          addressBarDomain,
        );

        const stateUpdate: InitialStateUpdate = {
          sessionConfig: sessionConfigResult,
          defaultSite: addressBarDomain, // domain in the addressBar was valid
          invalidDomain: '',
        };

        // Return result for effect state update.
        return stateUpdate;
      } catch (error) {
        // Try to initialize the app using the oidc tokens default site.
        // Error is likely due to the domain from address bar invalid.
        try {
          const sessionConfigResult = await initialSessionConfig(
            apiUrl,
            authToken,
            authBloxCMSDefaultSite,
          );

          const stateUpdate: InitialStateUpdate = {
            sessionConfig: sessionConfigResult,
            defaultSite: authBloxCMSDefaultSite, // fallback to their oidc default site.
            invalidDomain: addressBarDomain, // whatever was in the address bar as domain was invalid
          };

          return stateUpdate;
        } catch (error) {
          // If this errored likely user lacks cosmos permissions or failed to fetch, etc.
          throw error;
        }
      }
    },
    enabled: Boolean(auth.isAuthenticated && !auth.isLoading), // Enables and runs when the auth is valid and not loading.
    staleTime: Infinity,
    cacheTime: Infinity,
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  // Sync to state initCosmosQuery once it's available or changes.
  useEffect(() => {
    if (initCosmosQueryData) {
      setCosmosState((prevValues) => ({
        ...prevValues,
        ...initCosmosQueryData,
      }));
    }
  }, [initCosmosQueryData]);

  // getUserMe - fetches the user info once initCosmosQuery is successful.
  const {
    data: userMeQueryData,
    error: userMeQueryError,
    status: userMeQueryStatus,
  } = useQuery<UserAccountFull, ApiErrorResponse>({
    queryKey: ['CosmosProvider', 'getUserMe', cosmosState?.defaultSite],
    queryFn: async () => {
      const userInfo = await getUserMe(apiUrl, authToken, cosmosState?.defaultSite); // use determined active site
      return userInfo;
    },
    enabled: Boolean(cosmosState?.defaultSite && initCosmosQueryStatus === 'success'), // Fetch once the defaultSite is set + initCosmosQuery is successful.
    staleTime: Infinity,
    cacheTime: Infinity,
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  // Sync to state userMeData once it's available or changes.
  useEffect(() => {
    if (userMeQueryData) {
      setCosmosState((prevValues) => ({
        ...prevValues,
        userInfo: userMeQueryData,
      }));
    }
  }, [userMeQueryData]);

  // Mutation - Swap to the domain string provided.
  const { mutateAsync: changeActiveSiteAsync } = useMutation(
    async (newActiveSite: string) => {
      // On swapped site - Clear/reset/invalidate any errors, queries, states as we are about to refetch / reinitialize.
      queryClient.clear();
      await queryClient.invalidateQueries();
      await queryClient.resetQueries();
      // Reset the state values.
      setCosmosState(initialState);
      // Navigate to the new site domain before refetch since the address bar domain is used in the inital query.
      navigate(`/${newActiveSite.toLowerCase()}`);
      // Refetch initializeCosmosQuery.
      await initCosmosQueryRefetch();
    },
  );

  const contextValue = useMemo(() => {
    return {
      defaultSite: cosmosState?.defaultSite, // Note: defaultSite is the 'active' domain in the address bar.
      invalidDomain: cosmosState?.invalidDomain,
      sessionConfig: cosmosState?.sessionConfig,
      userInfo: cosmosState?.userInfo,
      authInfo: auth?.user || undefined,
      changeActiveSiteAsync: changeActiveSiteAsync,
      clearInvalidDomain: () => {
        setCosmosState((prevValues) => ({
          ...prevValues,
          invalidDomain: '',
        }));
      },
    };
  }, [changeActiveSiteAsync, cosmosState, auth?.user]);

  if (initCosmosQueryStatus === 'loading' || userMeQueryStatus === 'loading') {
    // Note this splashscreen can also show while awaiting the logout response from the oidc server
    // as the auth can invalidate the the 'enabled' flag on the initializeCosmosQuery causing react tree to re-render.
    return <BloxLoadingSplash message="Please wait..." />;
  }

  if (initCosmosQueryError || userMeQueryError) {
    const errorResults: ErrorDetails[] = [initCosmosQueryError, userMeQueryError]
      .filter(Boolean)
      .map((error) => {
        return {
          message: error?.message,
          status: error?.status,
          error: error,
        };
      });

    console.error('CosmosProvider Error:', errorResults);

    return (
      <ErrorAlerts
        severity="error"
        buttonText="Reload"
        buttonOnClick={() => {
          window.location.reload(); // Best to try a hard refresh if things fail this early...?
        }}
        errors={errorResults}
      />
    );
  }

  if (!canAccessCosmos) {
    return <NoCosmosPermissions />;
  }

  return <CosmosContext.Provider value={contextValue}>{children}</CosmosContext.Provider>;
};
