import { User, UserManager, WebStorageStateStore } from 'oidc-client-ts';
import {
  createContext,
  Fragment,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { AuthProvider, useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';
import ErrorAlerts, { ErrorDetails } from '../../components/generic/ErrorAlerts';
import BloxLoadingSplash from '../../components/generic/BloxLoadingSplash';

export interface CosmosOidcAuthToken {
  session_state: any;
  id_token: string;
  access_token: string;
  refresh_token: string;
  token_type: string;
  scope: string;
  expires_at: number;
  profile: {
    iss: string;
    sub: string;
    aud: string;
    iat: number;
    exp: number;
    email: string;
    'bloxcms.default_site': string;
  };
  [key: string]: any;
}

export interface EnvData {
  ENV?: string | undefined;
  API_SERVER_URL?: string | undefined;
  OIDC_SERVER_URL?: string | undefined;
  [key: string]: any;
}

export interface CosmosAuthEnvContextType {
  envState?: EnvData;
}

export interface CustomUserStateProperties {
  nav_to_cosmos_route?: string;
}

export const CosmosAuthEnvContext = createContext<CosmosAuthEnvContextType | undefined>(
  undefined,
);

export const useCosmosAuthEnv = () => {
  const context = useContext(CosmosAuthEnvContext);
  if (!context) {
    throw new Error('useCosmosAuthEnv must be used within a CosmosAuthEnvProvider!');
  }
  return context;
};

export const CosmosAuthEnvProvider = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate();
  const [errorResults, setErrorResults] = useState<ErrorDetails[]>([]);
  const [envState, setEnvState] = useState<EnvData>();

  // Fetch and store environment variables via <link rel="env" href="/env.json" /> or the process.env
  useEffect(() => {
    const loadEnv = async () => {
      const linkedEnvHref = window.document
        .querySelector('link[rel="env"]')
        ?.getAttribute('href');

      if (linkedEnvHref) {
        try {
          const envUrl = new URL(
            String(document.querySelector('link[rel="env"]')?.getAttribute('href')),
            window.location.origin,
          ).toString();

          const response = await fetch(envUrl);

          if (!response.ok) {
            throw new Error(
              `CosmosAuth - fetch envUrl bad response - !response.ok - got: ${JSON.stringify(
                response,
                null,
                2,
              )}`,
            );
          }

          const result = await response.json();

          if (!result.API_SERVER_URL || !result.OIDC_SERVER_URL || !result.ENV) {
            throw new Error(
              `CosmosAuth - fetched env is missing vars, got: ${JSON.stringify(
                result,
                null,
                2,
              )}`,
            );
          }

          sessionStorage.setItem('env', JSON.stringify(result)); // Save env in sessionStorage for use in dataProvider
          setEnvState(result); // Save to state for env data via the hook
        } catch (error) {
          console.error('CosmosAuth - error fetching env vars:', error);
          setErrorResults((prevArray) => [
            ...prevArray,
            {
              status: (error as ErrorDetails)?.status,
              message: (error as ErrorDetails)?.message,
              error: error,
            },
          ]);
        }
      } else {
        const envData = {
          ENV: process.env.REACT_APP_ENV,
          API_SERVER_URL: process.env.REACT_APP_API_SERVER_URL,
          OIDC_SERVER_URL: process.env.REACT_APP_OIDC_SERVER_URL,
        };
        console.log('CosmosAuth - fallback using process.env', envData);
        sessionStorage.setItem('env', JSON.stringify(envData));
        setEnvState(envData);
      }
    };
    loadEnv();
  }, []);

  // Stable user manager instance for use in the authProvider prop
  const userManagerMemo = useMemo(() => {
    return new UserManager({
      // Note: silent renew can't be run twice too fast (e.g. within React.StrictMode in dev), as server can recieve stale token and return error.
      automaticSilentRenew: true,
      stateStore: new WebStorageStateStore({ store: window.sessionStorage }),
      userStore: new WebStorageStateStore({ store: window.sessionStorage }),
      authority: envState?.OIDC_SERVER_URL || '',
      client_id: 'cosmos-admin',
      redirect_uri: window.location.origin,
      post_logout_redirect_uri: window.location.origin,
      scope: 'openid email offline_access',
      refreshTokenAllowedScope: 'openid email offline_access',
      fetchRequestCredentials: 'include',
      response_type: 'code',
      response_mode: 'query',
      metadataSeed: {
        end_session_endpoint: `${envState?.OIDC_SERVER_URL}-/logout`, // Temporary until CMSADMIN-85 & TNCMS-554796
      },
    });
  }, [envState?.OIDC_SERVER_URL]);

  const onSignInCallbackFn = async (user: User | void) => {
    if (!user) {
      console.error('[AuthProvider] Error: onSigninCallback - no user given!');
      return;
    }

    // Redirect to the page the user was trying to access, or was on prior to logout.
    const navUserToRoute = (user?.state as CustomUserStateProperties)
      ?.nav_to_cosmos_route;
    if (navUserToRoute) {
      navigate(navUserToRoute, { replace: true });
    }
  };

  const contextValue = useMemo(() => {
    return {
      envState: envState,
    };
  }, [envState]);

  if (errorResults.length !== 0) {
    for (const error of errorResults) {
      console.log('CosmosAuthEnvProvider Error:' + JSON.stringify(error, null, 2));
    }
    return <ErrorAlerts errors={errorResults} />;
  }

  if (!envState || !envState.OIDC_SERVER_URL) {
    return <BloxLoadingSplash message="Building Authentication Environment..." />;
  }

  return (
    <CosmosAuthEnvContext.Provider value={contextValue}>
      <AuthProvider
        userManager={userManagerMemo}
        onSigninCallback={onSignInCallbackFn}
      >
        <WithAuthChecks>{children}</WithAuthChecks>
      </AuthProvider>
    </CosmosAuthEnvContext.Provider>
  );
};

// Runs checks during session, eg redirecting to signin if unauthenticated, cleaning up stale states, error handling, etc.
export const WithAuthChecks = ({ children }: PropsWithChildren) => {
  const auth = useAuth();
  const [initialRun, setInitialRun] = useState(true);

  useEffect(() => {
    const asyncCheckAuthState = async () => {
      const redir_uri = window.location.origin;
      const cosmos_route = window.location.href.replace(window.location.origin, ''); // Remove 'hosted domain/origin' from href to get the routing path.

      if (!auth.isLoading && auth.error) {
        console.error('Authentication state error:', auth.error);
      }

      if (!auth.isLoading && !auth.isAuthenticated) {
        await auth.signinRedirect({
          redirect_uri: redir_uri,
          state: { nav_to_cosmos_route: cosmos_route },
        });
      }

      if (!auth.isLoading && auth.isAuthenticated && initialRun) {
        // Auth is ok, clear any stale states and set initialRun to false to render children.
        await auth.clearStaleState();
        setInitialRun(false);
      }
    };

    asyncCheckAuthState();

    // Event listener for token expiration. Library handles refreshing the token.
    return auth.events.addAccessTokenExpiring(async () => {
      await auth.clearStaleState(); // Not 100% sure if needed, but clearing any stale just in case.
    });
  }, [auth, initialRun]);

  if (initialRun) {
    return <BloxLoadingSplash message="Checking Authentication..." />;
  }

  return <Fragment key="with-auth-checks">{children}</Fragment>;
};
