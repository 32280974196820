import { assertResponseSuccess } from 'providers/functions';
import { CosmosCreateProvidersProps } from '../CosmosRAProvidersContext';
import { UrlMapNode, UrlMapResponse } from 'utility/types/dataProvider';

export const createUrlMapProvider = ({
  apiUrl,
  httpClient,
}: CosmosCreateProvidersProps) => ({
  // Get site URL maps
  getUrlMap: async (limit: number): Promise<UrlMapResponse> => {
    // Force limit to at least one
    if (limit < 1) limit = 1;
    try {
      const { json, status } = await httpClient(`${apiUrl}/core/urlmap/?limit=${limit}`);
      await assertResponseSuccess(json, status);

      return json;
    } catch (error) {
      console.error('[dataProvider] getUrlMap() error:' + error?.toString());
      throw error;
    }
  },

  // Get a URL map's root ('/') node
  getUrlMapRootNode: async (map_id: string): Promise<UrlMapNode> => {
    try {
      const { json, status } = await httpClient(`${apiUrl}/core/urlmap/${map_id}/root/`);
      await assertResponseSuccess(json, status);

      return json;
    } catch (error) {
      console.error('[dataProvider] getRootUrlMap() error:' + error?.toString());
      throw error;
    }
  },

  // Get URL map node
  getUrlMapNode: async (map_id: string, url_id: string): Promise<UrlMapNode> => {
    try {
      const { json, status } = await httpClient(
        `${apiUrl}/core/urlmap/${map_id}/nodes/${url_id}/`,
      );
      await assertResponseSuccess(json, status);

      return json;
    } catch (error) {
      console.error('[dataProvider] getUrlMapNode() error:' + error?.toString());
      throw error;
    }
  },

  // Create a new URL map node child
  createUrlMapNodeChild: async (
    map_id: string,
    url_id: string,
    data: UrlMapNode,
  ): Promise<UrlMapNode> => {
    try {
      const { json, status } = await httpClient(
        `${apiUrl}/core/urlmap/${map_id}/nodes/${url_id}/children/`,
        {
          method: 'POST',
          body: JSON.stringify(data),
        },
      );
      await assertResponseSuccess(json, status);

      return json;
    } catch (error) {
      console.error('[dataProvider] createUrlMapNodeChild() error:' + error?.toString());
      throw error;
    }
  },

  // Update a URL map node
  updateUrlMapNode: async (
    map_id: string,
    url_id: string,
    data: UrlMapNode,
  ): Promise<any> => {
    try {
      const { json, status } = await httpClient(
        `${apiUrl}/core/urlmap/${map_id}/nodes/${url_id}/`,
        {
          method: 'PUT',
          body: JSON.stringify(data),
        },
      );
      await assertResponseSuccess(json, status);

      return json;
    } catch (error) {
      console.error('[dataProvider] updateUrlMapNode() error:' + error?.toString());
      throw error;
    }
  },

  // Delete a URL map node
  deleteUrlMapNode: async (map_id: string, url_id: string): Promise<void> => {
    try {
      const { json, status } = await httpClient(
        `${apiUrl}/core/urlmap/${map_id}/nodes/${url_id}/`,
        { method: 'DELETE' },
      );
      await assertResponseSuccess(json, status);

      return json;
    } catch (error) {
      console.error('[dataProvider] deleteUrlMapNode() error:' + error?.toString());
      throw error;
    }
  },

  // Get URL map node children
  getUrlMapNodeChildren: async (
    map_id: string,
    url_id: string,
  ): Promise<UrlMapNode[]> => {
    try {
      const { json, status } = await httpClient(
        `${apiUrl}/core/urlmap/${map_id}/nodes/${url_id}/children/`,
      );
      await assertResponseSuccess(json, status);
      return json;
    } catch (error) {
      console.error('[dataProvider] getUrlMapNodeChildren() error:' + error?.toString());
      throw error;
    }
  },

  // Perform a depth search of a URL map
  searchUrlMap: async (
    map_id: string,
    query?: string,
    app?: string,
    alias?: string,
  ): Promise<UrlMapNode[]> => {
    if (!map_id) {
      throw new Error('Missing required parameter: map_id');
    }

    try {
      const url = new URL(`${apiUrl}/core/urlmap/${map_id}/depth/`);
      const params = new URLSearchParams({
        ...(alias && { alias }),
        ...(query && { query }),
        ...(app && { app }),
      });

      url.search = params.toString();

      const { json, status } = await httpClient(url.toString());

      await assertResponseSuccess(json, status);

      return json;
    } catch (error) {
      console.error('[dataProvider] searchUrlMap() error: ', error?.toString());
      throw error;
    }
  },
});
