import { Box, Typography } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import React from 'react';
import BloxCMSLogo from '../../assets/BloxCMSLogo';
import { bloxColors } from '../../themes/constants';

interface BloxLoadingSplashProps {
  message?: string;
  loadingPrimary?: string; // Unused, react admin typescript workaround.
  loadingSecondary?: string; // Unused, react admin typescript workaround.
}

const BloxLoadingSplash = React.memo(({ message }: BloxLoadingSplashProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: '55vw',
        margin: '0 auto',
        width: '100%',
      }}
    >
      <BloxCMSLogo sx={{ height: '100%', width: '100%' }} />

      {message ? (
        <Typography
          sx={{
            mb: 1,
            lineHeight: 1,
            fontSize: '1.25rem',
            textAlign: 'center',
          }}
        >
          {message}
        </Typography>
      ) : null}

      <LinearProgress
        variant="indeterminate"
        sx={{
          height: '6px',
          width: '100%',
          backgroundColor: bloxColors.white,
          '.MuiLinearProgress-barColorPrimary': {
            backgroundColor: bloxColors.logoblue,
          },
        }}
      />
    </Box>
  );
});

export default BloxLoadingSplash;
